import { currencyService, projectService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { ProjectStatusEnum } from '@/resource/enum';
import { CurrencyList, CustomerResource, ProjectResource } from '@/resource/model';
import { dateFormat, messageError, showWarningConfirm, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { SelectCurrency } from '@/views/dialogs';

@Component({
  components: { SelectCurrency }
})
export default class AddProject extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public project!: ProjectResource | null;

  @Prop({
    required: true,
    type: Array,
    default: () => {
      return [];
    }
  })
  public customers!: Array<CustomerResource>;
  public currencyTypeDialogVisible = false;

  public projectForm: {
    projectCode: string;
    customerId?: number;
    projectName: string;
    cycle: Array<string>;
    currencyName: string;
    currencyId?: number;
    budgetAmount?: number;
    remark: string;
  } = {
    projectCode: '',
    customerId: undefined,
    projectName: '',
    cycle: [],
    currencyName: '',
    currencyId: undefined,
    budgetAmount: undefined,
    remark: ''
  };

  public resourceFormRules = {
    projectName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('project.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          projectService
            .checkProjectName(value, this.projectForm.customerId!, this.project?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('common.nameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    customerId: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('project.selectCustomer')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    cycle: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('project.selectCycle')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    currencyName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('project.selectSettleCurrency')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  private operationType: 'add' | 'edit' = 'add';

  private get projectNameDisable(): boolean {
    if (this.project) {
      return this.project!.status === ProjectStatusEnum.underway;
    }
    return false;
  }

  /**
   * 打开选择币别弹窗
   */
  public openSelectCurrencyTypeDialog(): void {
    this.currencyTypeDialogVisible = true;
  }
  /**
   * 选中后数据处理
   */
  public handleSelectedCurrency(rowData: CurrencyList): void {
    this.projectForm.currencyId = rowData.id;
    this.projectForm.currencyName = rowData.name;
  }
  public async getCurrentType(): Promise<void> {
    const currentOptionData = await currencyService.getCurrencyList({}, { currentPage: 1, showCount: 50 });
    currentOptionData.data.forEach((item: any) => {
      const id: number = item.id;
      const name: string = item.name;
      switch (name) {
        case '人民币':
          this.projectForm.currencyId = id;
          this.projectForm.currencyName = name;
          break;
        default:
          break;
      }
    });
  }

  public dialogOpen(): void {
    if (!this.project) {
      this.operationType = 'add';
      this.title = 'project.addProject';
      this.getCurrentType();
      return;
    }
    this.operationType = 'edit';
    this.title = 'project.editProject';
    this.$nextTick(() => {
      Object.assign(this.projectForm, this.project);
      this.projectForm.cycle = [];
      if (this.project?.startTime && this.project.endTime) {
        this.projectForm.cycle.push(this.project?.startTime, this.project.endTime);
      }
    });
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.projectForm as Form).resetFields();
  }

  public async onSubmit(): Promise<void> {
    try {
      this.setLoading(true);
      await (this.$refs.projectForm as Form).validate();
      const project = ({ ...this.projectForm } as any) as ProjectResource;
      project.startTime = dateFormat(this.projectForm.cycle[0], 'YYYY-MM-DD');
      project.endTime = dateFormat(this.projectForm.cycle[1], 'YYYY-MM-DD');
      if (this.operationType === 'add') {
        // 检查客户下面的项目是否存在可完成但未完成的
        const checkRes = await projectService.checkProjectCompleteByCustomer(this.projectForm.customerId!);
        if (checkRes) {
          await showWarningConfirm(translation('project.cannotAddProjectTip'));
          this.closeDialog();
          this.$emit('add-disabled', this.projectForm.customerId);
          return;
        }
        await projectService.post(project);
        Message.success(translation('operationRes.addSuccess'));
        this.$emit('add-success');
        this.closeDialog();
        return;
      }
      await projectService.put(project);
      Message.success(translation('operationRes.editSuccess'));
      this.$emit('edit-success', project);
      this.closeDialog();
    } catch (error) {
      if (error && error !== 'cancel') {
        messageError(error);
      }
    } finally {
      this.setLoading(false);
    }
  }
}
