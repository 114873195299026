import { Component, Watch } from 'vue-property-decorator';
import { OsTable, OsQueryPlan } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { QueryPlanOperationOption } from '@/components/os-query-plan/os-query-plan';
import { CustomerResource, ProjectListQuery, ProjectResource } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  messageError,
  showWarningConfirm,
  translation,
  dateFormat,
  downloadFileByBlob,
  getTimeDiffDay,
  debounce
} from '@/utils';
import { Message } from 'element-ui';
import { PageQueryPlanEnum, ProjectStatusEnum } from '@/resource/enum';
import { cloneDeep } from 'lodash-es';
import { customerService, projectService } from '@/api';
import AddProject from './add-project/add-project.vue';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { CustomColumnMixin } from '@/mixins/custom-column';
import AssignProject from './assign-project/assign-project.vue';
import { NormalSelectOptions } from '@/resource/model/common';
@Component({
  name: 'Project',
  components: { AddProject, AssignProject }
})
export default class Project extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<ProjectResource> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<ProjectResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'projectCode',
      label: 'project.code',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'projectName',
      label: 'project.name',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'customerName',
      label: 'project.customer',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'status',
      label: 'project.status',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'progress',
      label: 'project.progress',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'prepressUncommittedTotal',
      label: 'project.unPrePressTotal',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'prepressToBeConfirmTotal',
      label: 'project.toBeConfirmPrepress',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'unDistributeTotal',
      label: 'project.unDistributeTotal',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'deliveryNoticeTotal',
      label: 'project.deliveryNotice',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'startTime',
      label: 'project.cycle',
      showOverflowTooltip: true,
      minWidth: '200px',
      formatter: (row: Object): string => {
        return `${dateFormat((row as ProjectResource).startTime, 'YYYY-MM-DD')} ~ ${dateFormat(
          (row as ProjectResource).endTime,
          'YYYY-MM-DD'
        )}`;
      }
    },
    {
      prop: 'currencyName',
      label: 'project.settleCurrency',
      showOverflowTooltip: true,
      minWidth: '130px'
    },
    {
      prop: 'quoteAmount',
      label: 'project.quotation',
      minWidth: '130px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as ProjectResource).quoteAmount?.toFixed(2) || '--';
      }
    },
    {
      prop: 'createUserName',
      label: 'common.createUser',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: object): string => {
        return dateFormat((row as ProjectResource).createTime);
      }
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<
    OsQueryItemOption<
      ProjectListQuery & {
        progressFlag: number;
      }
    >
  > = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'statusList',
      label: 'project.status',
      option: {
        placeholder: 'project.selectStatus',
        multiple: true
      },
      optionData: (): Array<{ label: string; value: ProjectStatusEnum }> => {
        return [
          {
            label: translation('projectStatus.new'),
            value: ProjectStatusEnum.new
          },
          {
            label: translation('projectStatus.underway'),
            value: ProjectStatusEnum.underway
          },
          {
            label: translation('projectStatus.waitingForAcceptance'),
            value: ProjectStatusEnum.waitingForAcceptance
          },
          {
            label: translation('projectStatus.completed'),
            value: ProjectStatusEnum.completed
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'project.customer',
      option: {
        placeholder: 'project.selectCustomer',
        filterable: true
      },
      optionData: []
    },
    {
      type: 'Input',
      field: 'createUserName',
      label: 'project.orderCreator',
      option: {
        placeholder: 'project.selectCreator'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'orderCreateTime',
      label: 'order.createDate',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): Boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    },
    {
      type: 'Select',
      field: 'isOverdue',
      label: 'project.isOverdue',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('isOverdue.theOverdue'),
            value: 1
          },
          {
            label: translation('isOverdue.HasBeenOverdue'),
            value: 2
          },
          {
            label: translation('isOverdue.BeyondOverdue'),
            value: 3
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'progressFlag',
      label: 'project.progressFlag',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: '100%',
            value: 1
          }
        ];
      }
    }
  ];

  /**
   * 页面标识
   */
  public code: number = PageQueryPlanEnum.projectListPage;
  /**
   * 查询方案编辑按钮
   */
  public queryPlanEndOption: Array<QueryPlanOperationOption> = [
    {
      id: 0,
      type: 'primary',
      slot: 'end',
      label: 'button.save',
      permissionCode: 'system:query:template:save',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).dialogOpened();
      }
    },
    {
      id: 1,
      type: 'text',
      slot: 'end',
      label: 'button.edit',
      permissionCode: 'system:query:template:delete',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).editDialogOpened();
      }
    }
  ];
  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'project:save',
      handleClick: (): void => {
        this.openProjectDialog();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'project.assign',
      operationType: 'assign',
      disabled: true,
      icon: 'el-icon-document',
      permissionCode: 'organize:department:project',
      handleClick: (): void => {
        this.openAssignProjectDialog();
      }
    },
    {
      slot: 'start',
      operationType: 'more'
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<ProjectResource> = {
    fixed: 'right',
    width: '220px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'project:edit',
        handleClick: (item: ProjectResource): void => {
          this.openProjectDialog(item);
        },
        dynamicHidden: (rowData: ProjectResource): boolean => {
          return rowData.status === ProjectStatusEnum.completed;
        }
      },
      {
        operationType: 'add',
        type: 'text',
        label: 'button.distributeOrder',
        icon: 'el-icon-plus',
        permissionCode: 'production:order:save',
        handleClick: (item: ProjectResource): void => {
          this.goCreateProductionOrder(item.id);
        },
        dynamicHidden: (rowData: ProjectResource): boolean => {
          return !(rowData.status !== ProjectStatusEnum.completed && rowData.unDistributeTotal > 0);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'project:delete',
        dynamicHidden: (rowData: ProjectResource): boolean => {
          return rowData.status !== ProjectStatusEnum.new;
        },
        handleClick: (item: ProjectResource): void => {
          this.deleteProject(item);
        }
      },
      {
        operationType: 'accepting',
        type: 'text',
        label: 'project.accepting',
        icon: 'el-icon-check',
        permissionCode: 'project:delete',
        dynamicHidden: (rowData: ProjectResource): boolean => {
          return rowData.status !== ProjectStatusEnum.waitingForAcceptance;
        },
        handleClick: (item: ProjectResource): void => {
          this.projectAccepting(item);
        }
      },
      {
        operationType: 'complete',
        type: 'text',
        label: 'project.complete',
        icon: 'el-icon-check',
        permissionCode: 'project:complete',
        dynamicHidden: (rowData: ProjectResource): boolean => {
          return rowData.progress !== 100 || rowData.status === ProjectStatusEnum.completed;
        },
        handleClick: (item: ProjectResource): void => {
          this.complete(item.id);
        }
      },
      {
        operationType: 'cancelComplete',
        type: 'text',
        label: 'project.cancelComplete',
        icon: 'el-icon-back',
        permissionCode: 'project:cancelComplete',
        dynamicHidden: (rowData: ProjectResource): boolean => {
          return rowData.status !== ProjectStatusEnum.completed;
        },
        handleClick: (item: ProjectResource): void => {
          this.cancelComplete(item.id);
        }
      }
    ]
  };

  public dialogVisible = false;

  public assignProjectVisible = false;

  public editRow: ProjectResource | null = null;

  public customers: Array<CustomerResource> = [];

  public selectedRows: Array<ProjectResource> = [];

  private queryForm: Partial<ProjectListQuery> = {
    createUserName: '',
    customerId: undefined,
    isOverdue: undefined,
    orderCreateTime: [],
    statusList: [],
    progressFlag: undefined
  };

  private defaultQueryForm: Partial<ProjectListQuery> = {
    createUserName: '',
    customerId: undefined,
    isOverdue: undefined,
    orderCreateTime: [],
    statusList: [],
    progressFlag: undefined
  };
  public activated(): void {
    this.queryClick();
  }
  public mounted(): void {
    this.queryClick();
  }

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'project');
    this.getCustomers();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    // 重置查询方案
    (this.$refs.OsQueryPlan as OsQueryPlan).id = 0;
    this.clearSelection();
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  @debounce()
  public queryClick(): void {
    if (this.$route.query.queryPlanName) {
      this.paging.currentPage = 1;
      (this.$refs.OsQueryPlan as OsQueryPlan).selectQueryPlan(this.$route.query.queryPlanName as string);
      delete this.$route.query.queryPlanName;
      return;
    }
    this.loadData();
  }
  /**
   * 执行查询方案
   * @param json
   */
  public queryList(json: string): void {
    this.paging.currentPage = 1;
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, JSON.parse(json));
    this.loadData();
  }

  public linkToDetails(project: ProjectResource): void {
    this.$router.push({
      path: 'project-details',
      query: {
        projectId: project.id.toString(),
        code: project.projectCode,
        customerId: project.customerId.toString(),
        customerName: project.customerName
      }
    });
  }

  public handleSelectionChange(selectedData: Array<ProjectResource>): void {
    this.selectedRows = selectedData;
  }

  public dialogClosed(): void {
    this.editRow = null;
  }

  public editSuccess(data: ProjectResource): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public getStatusClass(project: ProjectResource): string {
    switch (project.status) {
      case ProjectStatusEnum.new:
        return 'info-dot';
      case ProjectStatusEnum.underway:
        return 'primary-dot';
      case ProjectStatusEnum.waitingForAcceptance:
        return 'warning-dot';
      case ProjectStatusEnum.completed:
        return 'success-dot';
      default:
        return 'danger-dot';
    }
  }

  public getStatusName(project: ProjectResource): string {
    if (!project.status) {
      return translation('common.unKnownStatus');
    }
    return `projectStatus.${ProjectStatusEnum[project.status]}`;
  }

  public calculateProjectProgress(project: ProjectResource): number {
    if (!project.totalNum || project.totalNum === 0) {
      return 0;
    }
    return Math.floor((project.finishNumTotal / project.totalNum) * 100);
  }

  /**
   * 计算当前时间与项目结束时间的天数差
   */
  public calculateProjectEndTimeDiff(endTime: string): number {
    return getTimeDiffDay(new Date(), endTime);
  }

  public moreClick(command: 'delete' | 'export' | 'exportDetails' | 'exportFinanceDetails'): void {
    switch (command) {
      case 'delete':
        this.batchDeleteProject();
        break;
      case 'export':
        this.export();
        break;
      case 'exportDetails':
        this.exportItems();
        break;
      case 'exportFinanceDetails':
        this.exportFinanceDetails();
        break;
      default:
        break;
    }
  }

  public addDisabled(customerId: number): void {
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, { customerId: customerId, isOverdue: 3, progressFlag: 1, statusList: [2] });
    this.reloadData();
  }

  private goCreateProductionOrder(id: number): void {
    this.$router.push({
      path: '/add-production-order',
      query: {
        projectId: id.toString()
      }
    });
  }

  private openProjectDialog(data: ProjectResource | null = null): void {
    if (data) {
      this.editRow = cloneDeep(data);
    }
    this.dialogVisible = true;
  }

  private openAssignProjectDialog(): void {
    if (this.selectedRows.length === 0) {
      return;
    }
    this.assignProjectVisible = true;
  }

  private deleteProject(data: ProjectResource): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await projectService.delete(data.id);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private async batchDeleteProject(): Promise<void> {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows
            .filter(x => x.status === ProjectStatusEnum.new)
            .map(x => x.id);
          if (idList.length === 0) {
            Message.warning(translation('project.operationNoStandard'));
            this.clearSelection();
            return;
          }
          await projectService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private loadData(): void {
    this.tableOption.loading = true;
    projectService
      .getList(this.queryForm as ProjectResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.tableOption.data.forEach(x => {
          x.progress = this.calculateProjectProgress(x);
          x.daysToOverdue = this.calculateProjectEndTimeDiff(x.endTime);
        });
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private getCustomers(): void {
    customerService
      .getAllUsingCustomer()
      .then(res => {
        this.customers = res;
        const customerQuery = this.queryItemsOption.find(x => x.field === 'customerId');
        customerQuery!.optionData = res.map(x => {
          return { label: x.companyName, value: x.id };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  private async export(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const params = { idList: this.selectedRows.map(x => x.id), ...this.queryForm };
      const blob = await projectService.export(params);
      downloadFileByBlob(`${translation('project.exportFileName')}_${dateFormat(new Date())}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  private async exportItems(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const params = { idList: this.selectedRows.map(x => x.id), ...this.queryForm };
      const blob = await projectService.exportItems(params);
      downloadFileByBlob(`${translation('project.exportDetailFileName')}_${dateFormat(new Date())}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  private async exportFinanceDetails(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const params = { idList: this.selectedRows.map(x => x.id), ...this.queryForm };
      const blob = await projectService.exportFinanceDetails(params);
      downloadFileByBlob(`${translation('project.exportFinanceDetailFileName')}_${dateFormat(new Date())}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }
  /**
   * 项目验收
   */
  private projectAccepting(project: ProjectResource): void {
    if (project.status !== ProjectStatusEnum.waitingForAcceptance) {
      return;
    }
    showWarningConfirm(translation('project.confirmAccepting'))
      .then(async () => {
        try {
          await projectService.acceptance(project.id);
          Message.success(translation('project.acceptanceSuccessful'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  @Watch('queryForm.orderCreateTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.startCreateTime = undefined;
      this.queryForm.endCreateTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.startCreateTime = dateFormat(value[0]);
      this.queryForm.endCreateTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<ProjectResource>): void {
    const allowOperations = ['addDeliveryNotice', 'addInstallationNotice', 'assign'];
    this.operationOptions.forEach(x => {
      if (allowOperations.indexOf(x.operationType) > -1) {
        x.disabled = value.length !== 1;
      }
    });
  }

  private clearSelection(): void {
    (this.$refs.projectTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  /**
   * 完成项目
   * @param projectId 项目id
   */
  private async complete(projectId: number): Promise<void> {
    try {
      await showWarningConfirm(translation('project.confirmCompleteTip'));
      await projectService.complete(projectId);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      if (error !== 'cancel') messageError(error);
    }
  }

  /**
   * 取消完成项目
   * @param projectId 项目id
   */
  private async cancelComplete(projectId: number): Promise<void> {
    try {
      await showWarningConfirm(translation('project.confirmCancelCompleteTip'));
      await projectService.cancelComplete(projectId);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      if (error !== 'cancel') messageError(error);
    }
  }
}
